import React from "react"
import widont from "widont"

import styles from "./textImage.module.scss"
import Image from "../image"

const TextImage = props => {
  const img = props.data.image[0]

  return (
    <div className="module">
      <div className="wrapper">
        {props.data.sectionTitle && (
          <div className="columns columns is-tablet is-0-mobile is-centered no-vertical-gap">
            <div className="column is-10-tablet is-10-desktop is-centered text-center">
              <div className={styles.title}>
                <h2
                  className="h-2 h--cms-title"
                  dangerouslySetInnerHTML={{
                    __html: widont(props.data.sectionTitle),
                  }}
                />
              </div>
            </div>
          </div>
        )}
        <div
          className={`columns no-vertical-gap is-tablet is-vcentered ${
            styles.columns
          } ${props.data.reverseOrder ? styles.isReversed : null}`}
        >
          {/* Image */}
          <div className={`column is-half-tablet`}>
            <div className={styles.image}>
              <Image
                width={img.width}
                height={img.height}
                sizes="(min-width: 1344px) 652px, (min-width: 768px) calc(50vw - 6.25rem), calc(100vw - 2.5rem)"
                url={img.url}
                alt={img.altText}
              />
            </div>
          </div>

          {/* Text */}
          <div className={`column is-half-tablet ${styles.columnText}`}>
            {props.data.subhead && (
              <p className={`subhead ${styles.subhead}`}>
                {props.data.subhead}
              </p>
            )}
            {props.data.heading && (
              <h2
                className="h-2 mb-2 h--cms-title"
                dangerouslySetInnerHTML={{
                  __html: props.data.heading,
                }}
              />
            )}
            {props.data.body && (
              <div
                className="user-content"
                dangerouslySetInnerHTML={{
                  __html: props.data.body,
                }}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default TextImage
