import React from "react"
import { graphql } from "gatsby"

import ArticleHeader from "../../../components/athena/articleHeader.js"
import Contributors from "../../../components/athena/contributors.js"
import AthenaCta from "../../../components/athena/athenaCta.js"
import Image from "../../../components/image"
import MediaMedia from "../../../components/zeus-modules/mediaMedia"
import Layout, { athenaTheme } from "../../../components/layout"
import MoreArticles from "../../../components/athena/moreArticles.js"
import PreFooter from "../../../components/athena/preFooter"
import ScrollProgressBar from "../../../components/scrollProgressBar"
import SingleImage from "../../../components/zeus-modules/singleImage"
import TextImage from "../../../components/zeus-modules/textImage"

import styles from "./fluid-brand.module.scss"

let theme = {
  ...athenaTheme,
  background: styles.background,
}

const Article = ({ data }) => {
  const article = data.craft.article
  const seo = article.seomatic
  const coverImages = article.coverImages ? article.coverImages[0] : null
  const athenaCta = article.athenaCta

  // Assets
  const aestheticVariation = data.craft.aestheticVariation[0]
  const brandConvictions1 = data.craft.brandConvictions1[0]
  const brandConvictions2 = data.craft.brandConvictions2[0]
  const fluidScroll = data.craft.fluidScroll[0]
  const fluidBrandExamples = data.craft.fluidBrandExamples[0]
  const luxuryBrandLogos = data.craft.luxuryBrandLogos[0]
  const materialVariation = data.craft.materialVariation[0]
  const variatedBrands = data.craft.variatedBrands[0]
  const fluidNike1 = data.craft.fluidNike1[0]
  const fluidNike2 = data.craft.fluidNike2[0]
  const fluidNike3 = data.craft.fluidNike3[0]

  return (
    <Layout seo={seo} mode="athena" theme={theme}>
      <article>
        <ScrollProgressBar />
        <div className={styles.article}>
          <ArticleHeader
            issue={article.issue}
            title={article.title}
            subtitle={article.articleSubTitle}
            coverImages={coverImages}
          />
          <div className={styles.articleBody}>
            <div className="wrapper">
              <div className="columns is-tablet is-centered">
                <div className="column is-10-tablet is-8-desktop">
                  <div className="user-content-athena">
                    <p>
                      The brand has traditionally been a monolithic thing. Its
                      roots are straightforward — brands were literally just a
                      permanent sear, or brand mark, impressed onto livestock.
                      The marks weren’t just decoration — they meant something,
                      an implicit statement about ownership. “This is my cow.”
                      In other words, these marks became a concept that carried
                      meaning. For a brand, “meaning” has become a very powerful
                      thing. And with time, the meaning a brand can take on has
                      become far more complex, more nuanced, and infinitely
                      varied. Over centuries we’ve moved from brand as an
                      inherent mark of ownership to brand as a complex concept
                      that can shape our very understanding of the the world.
                    </p>
                    <p>
                      Many people feel like they’re living in an always-evolving
                      state of in-between-ness — like the ground itself feels a
                      bit wobbly. In fact, we are living in{" "}
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="http://www.metamodernism.com/2015/01/12/metamodernism-a-brief-introduction/"
                      >
                        a period of persistent reorientation with the world
                      </a>{" "}
                      brought on by constant change and cultural acceleration.
                      What’s behind this quickening? The internet.
                      Digital-everything. A collapse of distances. An ability to
                      see new ways to live and be always scrolling past on the
                      screen in front of you. It may be hard to understand and
                      follow, but it’s easy to feel.
                    </p>
                    <div style={{ width: "139px", margin: "5rem auto 5rem" }}>
                      <img
                        style={{ width: "100%", maxWidth: "100%" }}
                        src={fluidScroll.url}
                        alt=""
                      />
                      {/* <video
                      autoplay
                      loop
                      muted
                      playsinline
                      style={{ width: "139px", height: "139px" }}
                    >
                      <source
                        src="https://zj-v6.imgix.net/athena-content/Introduction/Fluid-Scroll-Background.gif?frame=1&w=278&h=278&fm=mp4"
                        type="video/mp4"
                      />
                    </video> */}
                    </div>
                    <p>
                      This cultural soup has created an immense desire for
                      change and newness — an aspiration to continually live in
                      a new way, and explore what it means to be human. And with
                      that has arrived a desire to not be pinned down as one
                      thing or another. As people, we acknowledge there could
                      always be something better out there.
                    </p>
                    <p>
                      Today, we value a breadth of life’s experiences — picking
                      and choosing the elements that we find attractive and
                      exploring their depths, occupying many thoughts and traits
                      at once — rather than selecting from a set of
                      pre-determined and tidily shaped identity packs that we
                      can buy into.
                    </p>
                    <p>
                      Whereas once we may have aspired to own a brand because it
                      captured who we were, today we know that the way we
                      construct ourselves is more nuanced and self-determined.
                      Today, a static brand can actually feel like a stiflingly
                      limiting thing — something you might avoid because it
                      defines you too narrowly or inaccurately. Flaunting a
                      brand still says a lot about you, for better or worse.
                      There are brands with rich meaning and varied expressions
                      that match the way that we think about ourselves. And
                      there are brands that are stuck in a rigid and outmoded
                      place — brands that try too hard to capture who they think
                      you want to be.
                    </p>
                  </div>
                </div>
              </div>

              <div className="columns is-tablet">
                <div className="column is-10-tablet is-7-desktop is-offset-2-desktop is-offset-1-tablet">
                  <blockquote className={styles.quote}>
                    <p>
                      Fluidity has become the{" "}
                      <span className="italic">
                        defining trait of our&nbsp;time
                      </span>
                      .
                    </p>
                  </blockquote>
                </div>
              </div>

              <div className="columns is-tablet is-centered">
                <div className="column is-10-tablet is-8-desktop user-content-athena">
                  <p>
                    A new generation is native to this pace — people who are
                    more sensitive and savvy to the meanings behind the{" "}
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://www.nytimes.com/interactive/2019/11/13/magazine/internet-teens.html"
                    >
                      things that they choose to let into their lives
                    </a>
                    . We’ve entered an age where the value of constant newness
                    is far more important than the idea of consistency.
                  </p>
                  <p>
                    Fluidity has become the defining trait of our time. But what
                    does fluidity actually mean? And what does a fluid brand
                    look like? We know Gen Z{" "}
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://www.fastcompany.com/3062475/your-guide-to-generation-z-the-frugal-brand-wary-determined-anti-millen"
                    >
                      hates brands
                    </a>
                    ,{" "}
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://adage.com/article/agency-viewpoint/gen-z-hates-ads-love-videos/309105"
                    >
                      despises advertising
                    </a>
                    , and sees through anything that’s trying to influence them.{" "}
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://www.adweek.com/programmatic/gen-z-will-dismantle-the-duopoly-unless-theyre-taken-more-seriously/"
                    >
                      Traditional brand loyalty
                    </a>{" "}
                    is a bore. Inertia is best avoided. And brands, by their
                    very historical definition, have been built upon rigid,
                    fixed notions of themselves: upon being recognizable as a
                    static mark of ownership.
                  </p>
                  <p>
                    So we asked ourselves: in this era of always-faster,
                    always-more, always-new, what’s fixed about a brand? And
                    what’s fluid? And how do you best create a brand identity in
                    an era of identity nomads?
                  </p>
                  <p>
                    First, a note for anyone who doesn’t know us: we don’t see
                    brand identity as a tidy exterior wrapping. A brand is made
                    up of everything you believe, everything you do, and every
                    action you take. That means branding is a holistic exercise,
                    one where every decision you make is a creative decision
                    that reinforces or expands your meaning. Your product,
                    services and aesthetics are a manifestation of your values
                    and beliefs.
                  </p>
                  <p>
                    In order to thrive and serve, brands must evolve and invent
                    at a pace faster than culture. And consistently show people
                    a new and better way to live.
                  </p>
                  <blockquote
                    className={`${styles.quote} ${styles.quoteCentered}`}
                  >
                    <p>
                      How do you create brand identity{" "}
                      <i>in an era of identity nomads?</i>
                    </p>
                  </blockquote>
                </div>
              </div>
            </div>
            <div className={styles.mediaMedia}>
              <MediaMedia
                data={{
                  smallMedia: [brandConvictions1],
                  largeMedia: [brandConvictions2],
                  verticalAlignment: "bottom",
                  reverseOrder: false,
                }}
              />
            </div>
            <div className="wrapper">
              <div className="columns is-tablet is-centered">
                <div className="column is-10-tablet is-8-desktop">
                  <div className="user-content-athena">
                    <h2 className="text-center">Convictions at the Core</h2>
                    <p>
                      If brands have to evolve faster than ever, and more
                      fluidly, what about it is fixed, keeping its axis in place
                      and locked from spinning out of control? We believe the
                      center of a brand is a conviction. A conviction begins
                      with a question a brand is asking:
                    </p>
                    <p>
                      Nike: What are humans physically capable of?
                      <br />
                      Apple: How creative can people be?
                      <br />
                      Facebook: How connected can we be? <br />
                      Tesla: How can we better power humanity?
                    </p>
                    <p>
                      These questions beg for a quest, if you like — an
                      exploration. They’re inherently rooted in cultural or
                      social issues. They take a broad approach, with their
                      focus on the betterment of people.
                    </p>
                    <blockquote className={styles.quote}>
                      <p>
                        We believe the center of a brand is{" "}
                        <i>a&nbsp;conviction</i>.
                      </p>
                    </blockquote>
                    <p>
                      Convictions ensure a brand is always in pursuit of
                      something. That you’re on a mission to create a better
                      version of yourself, day after day. And if you’re not
                      improving and experimenting against your conviction,
                      you’re waning. This is why creativity is so essentially a
                      part of the center of a brand — not just an aspect of its
                      external aesthetic expression. It’s the very lifeblood of
                      ongoing existence and expansion.
                    </p>
                    <p>
                      When you start with a big question, you can arrive at any
                      number of different big answers — a plethora of
                      possibilities to help people pursue a life that’s markedly
                      new, and hopefully better.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.singleImage}>
              <SingleImage
                data={{
                  image: [variatedBrands],
                  mobileImage: [variatedBrands],
                  columnWidth: "fullBleed",
                }}
              />
            </div>
            <div className="wrapper">
              <div className="columns is-tablet is-centered">
                <div className="column is-10-tablet is-8-desktop">
                  <div className="user-content-athena-no-headings">
                    <h2 className="a-h-2 text-center">Variated Brands</h2>
                    <p>
                      When it comes the expression of a company’s convictions,
                      we think of the best brands as being highly “variated.”
                      Variation is the source of continued life — it’s the
                      inventiveness and imagination that makes us distinctly
                      human. You can variate in two distinct ways. There’s
                      material variation: a change in your core product or
                      services. And there’s aesthetic variation: an outwardly
                      facing expression that signals something new and
                      attractive. It’s how you express change to the world.
                    </p>
                    <div style={{ width: "120px", margin: "5rem auto 2.5rem" }}>
                      <Image
                        height={materialVariation.height}
                        width={materialVariation.width}
                        sizes="120px"
                        url={materialVariation.url}
                        altText={materialVariation.altText}
                      />
                    </div>
                    <div className="mb-3">
                      <h3 className="subhead subhead--large fw-bold text-center">
                        Material Variation
                      </h3>
                    </div>
                    <p>
                      The most noticeable brands are coming to life in
                      surprising ways. They iterate on functionality and create
                      true innovation, leap-frogging instead of side-stepping.
                    </p>
                    <p>
                      This is how so many D2C upstarts have found momentum—they
                      pursue new functionality. Ten years ago, no one thought
                      that people would want to buy a mattress in a box, but
                      Casper showed there was a better way. At one point, no
                      consumer dreamed it was possible to track the sourcing and
                      production methods of your clothes, but Everlane proved
                      differently. The concept of hailing a random car and
                      taking it wherever you wanted was initially terrifying,
                      but turned out to be brilliant.
                    </p>
                    <p>
                      It doesn’t matter if these companies are successful: they
                      are noteworthy and highly visible, ultimately creating a
                      new appetite — a new aspiration for what’s possible. We
                      expect more from companies, and from life, and we value
                      the brands that bring this to us. In short, we have an
                      appetite for change that makes things suck less.
                    </p>
                    <p>
                      Since launching, Casper has evolved by asking the question
                      “how well can people sleep?” They’re not a mattress
                      company, but a sleep company. And they’re continuing to
                      pursue variation in this way, with new products and
                      services that promote sleep. Their conviction that sleep
                      can be better that defines their journey ahead.
                    </p>
                    <div style={{ width: "120px", margin: "5rem auto 2.5rem" }}>
                      <Image
                        height={aestheticVariation.height}
                        width={aestheticVariation.width}
                        sizes="120px"
                        url={aestheticVariation.url}
                        altText={aestheticVariation.altText}
                      />
                    </div>
                    <div className="mb-3">
                      <h3 className="subhead subhead--large fw-bold text-center">
                        Aesthetic Variation
                      </h3>
                    </div>
                    <p>
                      Aesthetics are all about attraction. Historically, a
                      brand’s visual update has been in the service of catching
                      up with cultural trends or ideas —{" "}
                      <i>
                        how do we mimic or better represent the things that we
                        see going on in culture?
                      </i>{" "}
                      But the most breakthrough brands are using aesthetics to
                      signal a shift in idea or material progress — to show off
                      a novel way of living, being, doing and seeing.
                    </p>
                    <p>
                      When it comes to aesthetic identity, we’re seeing
                      expressions of brand with far more visual variation. With
                      visual identity, there’s an inherent tension between
                      showcasing newness while still “getting credit” and
                      building equity. It’s a careful dance with no perfect
                      formula or answer.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className={styles.singleImage}>
              <SingleImage
                data={{
                  image: [fluidBrandExamples],
                  mobileImage: [fluidBrandExamples],
                  columnWidth: "fullBleed",
                }}
              />
            </div>

            <div className="wrapper">
              <div className={styles.offsetImages}>
                <div className={styles.offsetImagesCol}>
                  <img
                    style={{ width: "100%", maxWidth: "100%" }}
                    src={fluidNike1.url}
                    alt={fluidNike1.altText}
                  />
                </div>
                <div className={styles.offsetImagesCol}>
                  <Image
                    height={fluidNike2.height}
                    width={fluidNike2.width}
                    sizes="(min-width: 1334px) 450px, (min-width: 768px) calc(40vw - 2.5rem), calc(100vw - 2.5rem)"
                    url={fluidNike2.url}
                    altText={fluidNike2.altText}
                  />
                </div>
              </div>
            </div>

            <div className={styles.textImage}>
              <TextImage
                data={{
                  image: [fluidNike3],
                  reverseOrder: 1,
                  heading: "",
                  body: `<p>As part of this, we’re seeing once sacred brand marks and identities
            transform. Nike hangs close to its core equity but is iterating on
            it relentlessly to show off new ideas about lifestyle in a sped-up
            age, whether that be fluidity in gender, aspiration, or any other
            kind of personal definition.</p>`,
                }}
              />
            </div>

            <div className="wrapper">
              <div className="columns is-tablet is-centered">
                <div className="column is-10-tablet is-8-desktop">
                  <div className="user-content-athena">
                    <p>
                      We’re also seeing the inverse — brand marks becoming
                      simplified to serve as a platform for continued aesthetic
                      shifting. Look at the evolution in high-end lifestyle
                      brand marks over the past year — they are knowingly using
                      their mark as a staple element, a consistent, efficient
                      and pared-back stamp on a product or brand expression that
                      is inherently more cohesive. This pared-back minimalism
                      has{" "}
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.businessoffashion.com/articles/opinion/the-revolution-will-not-be-serifised-why-every-luxury-brands-logo-looks-the-same-burberry-balmain-balenciaga"
                      >
                        occasionally caused some controversy
                      </a>
                      , but it’s in the name of ultimately being more flexible
                      and fluid. The thinking is that the mark itself can’t
                      carry too many of its own visual connotations when the
                      rest of the brand expression is constantly changing.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.singleImage}>
              <SingleImage
                data={{
                  image: [luxuryBrandLogos],
                  mobileImage: [luxuryBrandLogos],
                  columnWidth: "fullBleed",
                }}
              />
            </div>
            <div className="wrapper">
              <div className="columns is-tablet is-centered">
                <div className="column is-10-tablet is-8-desktop">
                  <div className="user-content-athena">
                    <p>
                      There are also brands or entities that are dropping
                      aesthetic consistency altogether — mostly now in the art
                      and{" "}
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://fkatwi.gs/"
                      >
                        music spaces
                      </a>{" "}
                      — and instead depending on the savviness of their fans to
                      connect the dots for themselves. An artist doesn’t need a
                      consistent visual brand when it’s creativity their
                      audience is seeking. Variation is a gift to their fans —
                      it represents the exploration of new ideas in the style
                      only the artist can achieve. And if we think about brands
                      in this context, as part of the expanding cultural soup we
                      find ourselves in — they feel stodgy and fixed.
                    </p>
                    <p>
                      We think we’re only seeing the beginning of aesthetic
                      variation. In the future, too many rigid elements may
                      carry negative side effects. We’re only beginning to see
                      the ways we can represent new ideas visually.
                    </p>
                    <h2 className="text-center">Fluid Futures and You</h2>
                    <p>
                      If fluidity in culture means a constant demand for
                      material and aesthetic newness — what does it mean for
                      your brand? We’ve been working with some of the world’s
                      most pioneering companies on this very question. We think
                      there are two core considerations that come out of this
                      shift — how do you structure a company to incentivize and
                      operationalize variation?
                    </p>
                    <p>
                      And how do you size up and understand an audience that’s
                      always shape-shifting?
                    </p>
                    <p>
                      In this first issue of Athena, we explore both. And much
                      more.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <Contributors
              zeus={article.zeusContributors}
              other={article.otherContributors}
            />
            <AthenaCta data={athenaCta} />
            <MoreArticles id={article.id} issue={article.issue[0].id} />
          </div>
          {/* .article-body */}
        </div>
        {/* .article */}
      </article>
      <PreFooter />
    </Layout>
  )
}

export default Article

export const query = graphql`
  query($slug: String!) {
    craft {
      article: entry(slug: [$slug], section: "athenaArticles") {
        id
        title
        seomatic {
          metaTagContainer
          metaLinkContainer
          metaTitleContainer
          metaJsonLdContainer
        }
        ... on Craft_athenaArticles_athenaArticleCustom_Entry {
          articleSubTitle
          issue {
            id
            title
          }
          coverImages {
            ... on Craft_coverImages_BlockType {
              ratio_16x9 {
                id
                url
                width
                height
                ... on Craft_zjContent_Asset {
                  altText
                }
              }
              ratio_4x3 {
                id
                url
                width
                height
                ... on Craft_zjContent_Asset {
                  altText
                }
              }
            }
          }
          zeusContributors {
            id
            title
            uri
          }
          otherContributors {
            ... on Craft_otherContributors_TableRow {
              name
              website
            }
          }
          athenaCta {
            ...AthenaCta
          }
        }
      }
      aestheticVariation: assets(id: 1209) {
        url
        width
        height
        ... on Craft_athenaContent_Asset {
          altText
        }
      }
      brandConvictions1: assets(id: 1210) {
        url
        width
        height
        ... on Craft_athenaContent_Asset {
          altText
        }
      }
      brandConvictions2: assets(id: 1211) {
        url
        width
        height
        ... on Craft_athenaContent_Asset {
          altText
        }
      }
      fluidScroll: assets(id: 1219) {
        url
        width
        height
        ... on Craft_athenaContent_Asset {
          altText
        }
      }
      fluidBrandExamples: assets(id: 1215) {
        url
        width
        height
        ... on Craft_athenaContent_Asset {
          altText
        }
      }
      luxuryBrandLogos: assets(id: 1216) {
        url
        width
        height
        ... on Craft_athenaContent_Asset {
          altText
        }
      }
      materialVariation: assets(id: 1217) {
        url
        width
        height
        ... on Craft_athenaContent_Asset {
          altText
        }
      }
      variatedBrands: assets(id: 1218) {
        url
        width
        height
        ... on Craft_athenaContent_Asset {
          altText
        }
      }
      fluidNike1: assets(id: 2280) {
        url
        width
        height
        ... on Craft_athenaContent_Asset {
          altText
        }
      }
      fluidNike2: assets(id: 2277) {
        url
        width
        height
        ... on Craft_athenaContent_Asset {
          altText
        }
      }
      fluidNike3: assets(id: 2278) {
        url
        width
        height
        ... on Craft_athenaContent_Asset {
          altText
        }
      }
    }
  }
`
